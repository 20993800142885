import { getDevicesSingleLoaction, getAddress } from '@/api/amap';
import gcoord from "gcoord";

// 设备定位信息
export const getDeviceLocationByCode = async (that) => {
  const res = await getDevicesSingleLoaction(that.deviceInfo.code);

  const obj = res[0];

  that.deviceLocation = obj ?  obj: {};

  const [ longitude, latitude ]  = convertLngLat(obj);
  that.deviceLocation.longitude = longitude;
  that.deviceLocation.latitude = latitude;
  that.location = [ longitude, latitude ];
  await getLocationInfoFromAmap(that);
}


// 高德获取坐标信息
export const getLocationInfoFromAmap = async (that) => {
  const res = await getAddress(that.location.join());
  that.locationAddress = res.regeocode || {};
}

export const convertLngLat = ({longitude, latitude}) => {
  return gcoord.transform(
    [ longitude, latitude ],
    gcoord.WGS84,
    gcoord.GCJ02
  );
}

export default {
  getDeviceLocationByCode,
  convertLngLat
}