import request from '@/utils/request';

// 列表
export const list = (params) => {
    const rand = Date.now();
    return request.get(`Site/List?${rand}`, { params });
}
// 增加
export const add = (data) => {
    const rand = Date.now();
    return request.put(`Site/add?${rand}`, data);
}
// 编辑
export const edit = (data) => {
    const rand = Date.now();
    return request.post(`Site/update?${rand}`, data);
}

// 删除
export const del = (idList) => {
    const rand = Date.now();
    return request.get(`Site/delete/${idList}?${rand}`);
}

// 用户关系表
export const userSites = (siteId) => { // 这里的 siteId 是 userId
    const rand = Date.now();
    return request.get(`Site/device/site?${rand}`, { params: { siteId } });
}

// 详情
export const detail = (siteId) => {
    const rand = Date.now();
    return request.get(`Site/${siteId}?${rand}`);
}

export const listDetail = async (siteName) => {
    const rand = Date.now();
    try {
        const params = { siteName };
        const res = await request.get(`Site/List?${rand}`, { params });
        return res.data[0];
    } catch {
        return null;
    }
    
}

export default {
    list,
    add, 
    edit,
    del,
    detail,
    userSites,
    listDetail
}