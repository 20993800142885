var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: "12" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "站点位置", prop: "regionName" } },
                [
                  _c("el-input", {
                    ref: "region",
                    style: `${
                      _vm.showOnly ? "width: calc(100% - 75px);" : ""
                    } padding-right:10px;`,
                    attrs: {
                      readonly: _vm.isDetail,
                      size: "mini",
                      placeholder: "请输入所在城市+详细地址，如：北京市天安门",
                      clearable: "",
                    },
                    on: { blur: _vm.getLocationFromAddress },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.getLocationFromAddress.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.siteInfo.regionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.siteInfo, "regionName", $$v)
                      },
                      expression: "siteInfo.regionName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: "6" } },
            [
              _c("el-form-item", { attrs: { label: "经度" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.siteInfo.longitude)
                        ? Number(_vm.siteInfo.longitude || 0).toFixed(6)
                        : ""
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: "6" } },
            [
              _c("el-form-item", { attrs: { label: "纬度" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.siteInfo.latitude)
                        ? Number(_vm.siteInfo.latitude || 0).toFixed(6)
                        : ""
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "map-box",
        style: `${
          _vm.showOnly
            ? "width:100%;"
            : "margin-left:80px; width:calc(100% - 80px);"
        }; margin-top:15px; height: ${_vm.mapHeight}px;`,
        attrs: { id: "amap-box" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }