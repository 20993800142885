<template>
  <div v-loading="loading">
    <el-row>
      <el-col span="12">
        <el-form-item  label="站点位置" prop="regionName">
          <el-input :readonly="isDetail" ref="region" size="mini" :style="`${showOnly ? 'width: calc(100% - 75px);': ''} padding-right:10px;`"
            v-model="siteInfo.regionName" placeholder="请输入所在城市+详细地址，如：北京市天安门" clearable
              @keyup.enter.native="getLocationFromAddress" @blur="getLocationFromAddress" />
        </el-form-item>
      </el-col>
      <el-col span="6">
        <el-form-item label="经度">
          {{ utils.checkNumberIsNotEmpty(siteInfo.longitude) ?  Number(siteInfo.longitude || 0).toFixed(6) : "" }}
        </el-form-item>
      </el-col>
      <el-col span="6">
        <el-form-item  label="纬度">
          {{ utils.checkNumberIsNotEmpty(siteInfo.latitude) ?  Number(siteInfo.latitude || 0).toFixed(6) : "" }}
        </el-form-item>
      </el-col>
    </el-row>
    <div id="amap-box" class="map-box" :style="`${showOnly ? 'width:100%;' : 'margin-left:80px; width:calc(100% - 80px);'}; margin-top:15px; height: ${mapHeight}px;`">
    </div>
  </div>
</template>
<script>
  import { getLocationInfoFromAmap } from "@/utils/real-time-map";
  
  import mapService from '@/utils/mapService';
  
  export default {
    props: {
      siteInfo: undefined,
      height: undefined,
      isIndex: {
        type: Boolean,
        default: false
      },
      isDetail: {
        type: Boolean,
        default: false
      },
      showOnly: {
        type: Boolean,
        default: false
      },
      isAdd: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return{
        utils: $utils,
        loading: false,
        location: LngLat,
        extraOptions: {
          resizeEnable: true,
          liteStyle: true
        },
        map: null,
        zoom: 18,
        deviceLocation: {},
        locationAddress: {}
      }
    },
    watch: {
      siteInfo: function () {
        this.getDatas();
      },
      height: function () {
        this.mapHeight = this.height - 70;
  
        this.$forceUpdate();
        this.drawMap();
      }
    },
    async created () {
      console.log("region-core");
      await this.initAMapPlugin();
    },
    mounted () {
      if (this.isAdd) {
        this.$refs.region.focus();
        this.drawMap();
      }
      if (this.isIndex) {
        this.getDatas();
      }
      this.mapHeight = this.height - 70;
      this.$forceUpdate();
    },
    methods: {
      async getDatas () {
        try {

          if (!this.siteInfo || !$utils.checkObjectIsNotEmpty(this.siteInfo.location)
          ) return;

          this.loading = true;
          this.location = this.siteInfo.location;
  
          this.deviceLocation = this.siteInfo;
  
          await getLocationInfoFromAmap(this);
          this.siteInfo.regionName = this.locationAddress.formatted_address;
          this.drawMap();
        } catch (e) {
          console.log(e);
          this.$message.error("读取失败5");
        } finally {
          this.loading = false;
        }
      },
      async initAMapPlugin () {
        AMap.plugin(['AMap.Scale', "AMap.ToolBar"], () => {});
      },
      drawMap () {
  
        const that = this;
  
        this.$nextTick(() => {
          if (that.map !== null) {
            that.map.destroy();
          }
          that.map = null;
  
          const map = new AMap.Map("amap-box", {
            resizeEnable: !this.isDetail,
            zoom: that.zoom,
            center: that.location,
          });
  
          const marker = new AMap.Marker({
            position: that.location,
            type: "image",
            image: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            size: [25, 34],
            imageSize: [135, 40],
            imageOffset: [-9, -3]
          });
  
          map.add(marker);
  
          if (!this.isDetail) {
            const scale = new AMap.Scale({
              visible: true
            }),
            toolBar = new AMap.ToolBar({
              visible: true
            });
  
            map.addControl(scale);
            map.addControl(toolBar);
  
            map.on("click", this.onMapClick);
          }
  
          that.map = map;
        });
  
  
      },
      async onMapClick(e) {
        const location = (e.lnglat) ?  [e.lnglat.lng, e.lnglat.lat] : [ null, null ];
  
        this.siteInfo.location = location
        this.siteInfo.longitude = location[0];
        this.siteInfo.latitude = location[1];
  
        this.getDatas();
      },
      getLocationFromAddress () {
        if (this.isDetail) return;
        if (!this.siteInfo.regionName) return;
  
        this.loading = true;
  
        mapService.request({ // 查询设备坐标对应省份，城市
          url: '/geocode/geo',
          method: 'get',
          params: {
            address: this.siteInfo.city + this.siteInfo.regionName
          }
        }).then(async res => {
          const succeed = res.status === "1";
          if (succeed) {
            const location = res.geocodes[0].location.split(",");
            this.siteInfo.location = location
            this.siteInfo.longitude = location[0];
            this.siteInfo.latitude = location[1];
            this.getDatas();
            this.$refs.region.blur();
          } else {
            this.$message({
              message: "地址信息获得失败，请确认您的输入",
              type: "error"
            });
          }
          this.loading = false;
        });
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  ::v-deep .map-label-class {
    width: 60px;
  }
  
  .region-input-box {
    width: 100%;
    margin-top: -5px;
  }
  
  
  .map-box {
    margin-top:5px;
  }
  </style>