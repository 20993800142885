import request from '@/utils/request';

// 单个设备位置信息
export function getDevicesSingleLoaction(id) {
  const rand = Date.now();
  return request.get(`devices/${id}/location/single?${rand}`);
}


// 设备经纬度

export function getDevicesLoaction(data) {
  const rand = Date.now();
  return request.post(`devices/Location?${rand}`, data);
}

export function getAddress (location) {
  const rand = Date.now();
  return request.get(`devices/address?${rand}&location=${location}`);
}